import { AxiosResponse } from 'axios';
import { User } from 'server-sdk/src/types';
import marbleApi from 'ui-sdk/src/call';

const isLoggedIn = (user: User): boolean => {
  if (user) {
    return true;
  }

  return false;
};

interface LoginResponse {
  user: User;
  authToken: string;
}

const login = (
  email: string,
  password: string,
): Promise<AxiosResponse<LoginResponse>> =>
  marbleApi.post(
    '/users/auth',
    {
      email,
      password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

const logout = (): Promise<AxiosResponse> => marbleApi.post('/users/logout');

export { isLoggedIn, login, logout };

export type { LoginResponse };
