import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UserType } from 'server-sdk/src/types';
import { Login } from 'src/components';
import { useReduxState } from 'ui-sdk/src/hooks';
import { isLoggedIn } from 'src/services/auth';
import { login } from 'ui-sdk/src/thunks/identity';

export default function ({ location }) {
  const identity = useReduxState((state) => state.identity);
  console.log('login 1');
  const dispatch = useDispatch();
  const router = useRouter();

  useMemo(() => {
    console.log('login 2');
    if (location?.search) {
      console.log('login 3');
      const params = new URLSearchParams(location.search);
      const email: string = params.get('email')!;
      const password: string = params.get('otp')!;
      console.log('attempt login: ', email, password);

      dispatch(login(email, undefined, password));
    }
  }, [location?.search, identity.user]);

  if (isLoggedIn(identity.user)) {
    router.push(identity.user.type === UserType.Owner ? '/owner' : '/renter');
  }

  return <Login />;
}
