import React from 'react';
import { UserType } from 'server-sdk/types';
import { Button } from 'src/base/inputs/Button';
import { Form } from 'src/base/inputs/Form';
import { useCreateAccount } from 'ui-sdk/src/hooks/identity';

const CreateAccountForm: React.FC = () => {
  const form = useCreateAccount();
  const user = form.values;
  return (
    <div>
      <Form
        form={form}
        steps={[
          {
            label: "What's your first name?",
            name: 'firstName',
            placeholder: 'First Name',
            required: true,
            type: 'text',
          },
          {
            label: "What's your last name?",
            name: 'lastName',
            placeholder: 'Last Name',
            required: true,
            type: 'text',
          },
          {
            label: 'Are you an Owner or Renter?',
            name: 'type',
            required: true,
            placeholder: 'Role',
            type: 'dropdown',
            options: [
              {
                value: UserType.Owner,
                label: 'Owner',
              },
              {
                value: UserType.Renter,
                label: 'Renter',
              },
            ],
            color: 'primary',
          },
          {
            label: 'Enter your email',
            name: 'email',
            placeholder: 'Email',
            required: true,
            type: 'email',
            dontValidateOnChange: true,
          },
          {
            label: 'Enter your phone number',
            name: 'phone',
            placeholder: 'Phone number',
            required: true,
            type: 'phone',
            dontValidateOnChange: true,
          },
          {
            label: 'How many rental units are in your portfolio?',
            name: 'portfolioCount',
            placeholder: 'Unit Count',
            required: false,
            type: 'number',
            dontValidateOnChange: true,
            hidden: user?.type !== UserType.Owner,
          },
          {
            label: 'How did you hear about Marble?',
            name: 'refSource',
            required: true,
            placeholder: 'Source',
            type: 'dropdown',
            options: [
              {
                value: 'friend',
                label: 'Friend',
              },
              {
                value: 'agent',
                label: 'Real Estate Agent',
              },
              {
                value: 'listing',
                label: 'Listing Watermark',
              },
              {
                value: 'tenant',
                label: 'Tenant',
              },
              {
                value: 'google',
                label: 'Google',
              },
              {
                value: 'facebook',
                label: 'Facebook',
              },
              {
                value: 'blog',
                label: 'Blog Post',
              },
              {
                value: 'podcast',
                label: 'Podcast',
              },
              {
                value: 'other',
                label: 'Other',
              },
            ],
            color: 'primary',
          },
          {
            label: 'Referral Code (optional)',
            name: 'inboundReferralCode',
            placeholder: 'Referral Code',
            required: false,
            type: 'text',
          },
        ]}
      />
      <Button
        title="Create Account"
        onClick={() => {
          form.onSubmit();
        }}
        color="secondary"
        fullWidth
        style={{
          marginTop: 40,
        }}
        disabled={
          !user ||
          !user.email ||
          !user.firstName ||
          !user.lastName ||
          user.type === undefined ||
          !user.phone ||
          form.dirty
        }
      />
    </div>
  );
};

export default CreateAccountForm;
