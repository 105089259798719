import { Box } from '@mui/material';
import React, { useState } from 'react';
import { BaseContainer } from 'src/base/BaseContainer';
import { MarbleButton } from 'src/base/inputs/Button';
import { Form } from 'src/base/inputs/Form';
import { MarbleText } from 'src/base/texts/Text';
import { useOtpForm } from 'ui-sdk/src/hooks/identity';
import { theme as marbleTheme, theme } from 'ui-sdk/src/theme';

export const Login: React.FC = () => {
  const [isPhone, setIsPhone] = useState(true);
  const otp = useOtpForm();

  return (
    <BaseContainer>
      <div
        style={{
          maxWidth: 500,
          margin: 'auto',
          padding: marbleTheme.spacing(4),
          paddingTop: 40,
        }}
      >
        <MarbleText size="xl">Sign in to your account</MarbleText>
        <div
          style={{
            paddingTop: marbleTheme.spacing(3),
            paddingBottom: 0,
            flexDirection: 'row',
            alignContent: 'end',
          }}
        >
          <Form
            form={otp}
            steps={[
              {
                label: 'Enter your email',
                name: isPhone ? 'phone' : 'email',
                placeholder: isPhone ? 'Phone' : 'Email',
                required: true,
                type: isPhone ? 'phone' : 'email',
              },
            ]}
          />
          <Box
            sx={{
              pt: 2,
              flex: 'display',
            }}
          >
            <MarbleButton
              title="Submit"
              color="blue"
              variant="contained"
              style={{
                marginTop: marbleTheme.spacing(4),
              }}
              onClick={() => {
                otp.onSubmit();
              }}
              disabled={otp.dirty}
            />
            <MarbleButton
              title={isPhone ? 'Login via Email' : 'Login via Text'}
              sx={{
                ml: 2,
              }}
              color="blue"
              variant="outlined"
              onClick={() => {
                if (isPhone) {
                  otp.setValue('phone', '');
                } else {
                  otp.setValue('email', '');
                }
                setIsPhone(!isPhone);
                otp.resetForm();
              }}
            />
          </Box>
        </div>

        <div style={{ paddingTop: theme.spacing(10) }}>
          <MarbleText>
            Don't have an account? <a href="/start">Get started!</a>
          </MarbleText>
        </div>
      </div>
    </BaseContainer>
  );
};
