import React from 'react';
import { PayWall } from 'src/base';
import { usePropertySubscription, useUser } from 'ui-sdk/src/hooks';
import { Experiment } from '../Experiment';

interface PropertyPayWallProps {
  id?: number;
}

export const PropertyPayWall: React.FC<PropertyPayWallProps> = ({
  children,
  id,
}) => {
  const { active } = usePropertySubscription(id);
  const { me } = useUser();

  return (
    <Experiment
      control={
        <PayWall paid={active} id={id}>
          {children}
        </PayWall>
      }
      e={
        <PayWall paid={active || me?.trial_days_remaining > 0} id={id}>
          {children}
        </PayWall>
      }
    />
  );
};
