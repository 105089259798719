import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { identify } from 'ui-sdk/src/util/track';
import { RootState } from 'ui-sdk/src/store';
import { isLoggedIn } from '../../services/auth';

interface AuthenticatedRouteProps {
  guid?: string;
  children?: any;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  guid,
  children,
}: AuthenticatedRouteProps) => {
  const router = useRouter();
  const identity = useSelector((state: RootState) => state.identity);
  if (typeof location === 'undefined') {
    return <div />;
  }

  if (
    !isLoggedIn(identity.user) &&
    router.isReady &&
    // we can authenticate users using a guid, which is a 36 digit string
    (!guid || guid?.length < 36)
  ) {
    router.push('/login');
  }

  useEffect(() => {
    if (identity?.user?.id) {
      identify(identity.user.id);
    }
  }, [identity.user]);

  return <div>{children}</div>;
};
