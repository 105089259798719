import React from 'react';
import { BaseContainer } from 'src/base/BaseContainer';
import { H3 } from 'src/base/texts/H3';
import CreateAccountForm from 'src/components/app/auth/CreateAccountForm';

export const CreateAccount: React.FC = () => (
  <BaseContainer>
    <div
      style={{
        maxWidth: 500,
        margin: 'auto',
        paddingTop: 40,
      }}
    >
      <H3
        style={{
          paddingBottom: 30,
        }}
      >
        Welcome! Please create an account.
      </H3>
      <CreateAccountForm />
    </div>
  </BaseContainer>
);
